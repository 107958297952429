import React from "react";
import MarkdownElement from 'react-markdown'
import Anchor from '../Anchor';

export function Markdown({ colour, slug, title, subtitle, body, width }) {
  const cssWidth = width ? `is-${width}` : 'is-10';
  const classes = `section-${colour} markdown`;

  return (
    <div className={classes}>
      <Anchor id={slug} />
      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className={`column ${cssWidth} is-centered`} data-aos="fade">
            <h1 className="title has-text-centered section-title">{title}</h1>
            <h2 className="subtitle has-text-centered">{subtitle}</h2>
            <br />
          </div>
        </div>
        <div className="columns is-multiline is-centered" data-aos="fade">
          <div className={`column ${cssWidth} body`}>
            <MarkdownElement>{body}</MarkdownElement>
          </div>
        </div>
      </div>
    </div>
  );
}
