import React, { Fragment } from "react";

export function Footer({ items }) {
  return (
    <div className="footer">
      <p>
        {items.map((item, i) => (
          <Fragment key={i}>
            <span>
              {item.url ?
                <a key={item.text} href={item.url}>
                  {item.text}
                </a> :
                item.text
              }
            </span>
          </Fragment>
        ))}
      </p>
    </div>
  );
}
